import { CoreMenu } from '@core/types';



// Fonction pour créer le menu en fonction de la valeur actuelle de project
export const menu: CoreMenu[] = [
        {
    id: 'apps',
    type: 'section',
    title: 'Menu',
    translate: 'MENU.DASHBOARD.HO',
    icon: 'package',
    children: [
        {
            id: 'Voltix',
            title: 'Voltix',
            translate: 'MENU.DASHBOARD.VOLTIX',
            type: 'collapsible',
            icon: 'zap',
            classes:'badge badge-pill badge-light-Secondary waves-effect waves-float waves-light ng-star-inserted',
            // Afficher la section seulement si project est "Voltix"
            // Appel immédiat de la fonction
            children: [
                {
                    id: 'company',
                    title: 'Entreprises',
                    translate: 'MENU.DASHBOARD.ENTREPRISE',
                    type: 'item',
                    role:['SuperAdmin','AdminPartner'],
                    icon: 'globe',
                    url: 'company',

                },
                {
                    id: 'dashboard',
                    title: 'Tableau de bord',
                    translate: 'MENU.DASHBOARD.dashboard',
                    role:['Admin','User'],
                    type: 'item',
                    icon: 'monitor',
                    url: 'dashboard',

                },
                {
                    id: 'comapreZones',
                    title: 'Comparer',
                    translate: 'COMPARE.COMPARE',
                    role:['Admin','User'],
                    type: 'item',
                    icon: 'activity',
                    url: 'compareZones',

                },
                {
                    id: 'alertes',
                    title: 'Alertes',
                    translate: 'ALERTES.ALERTES',
                    role:['Admin','User'],
                    type: 'item',
                    icon: 'alert-triangle',
                    url: 'alertes',

                },

                /* {
                     id: 'dashboard',
                     title: 'Site',
                     translate: 'MENU.DASHBOARD.dashboard',
                     role:['User'],
                     type: 'item',
                     icon: 'monitor',
                     url: 'dashboardSite',
                 },*/
                /*{
                    id: 'siteV',
                    title: 'Sites',
                    translate: 'MENU.DASHBOARD.SITE',
                    type: 'item',
                    icon: 'map-pin',
                    url: 'siteV'
                },*/
                {
                    id: 'boxV',
                    title: 'Boitiers',
                    translate: 'MENU.DASHBOARD.BOX',
                    type: 'item',
                    icon: 'layout',
                    url: 'boxV',

                },
                {
                    id: 'settingV',
                    title: 'Paramètres',
                    translate: 'MENU.DASHBOARD.PARAMETRE',
                    type: 'item',
                    role:['Admin','User'],
                    icon: 'settings',
                    url: 'settingV',

                },
                {
                    id: 'user',
                    title: 'Utilisateurs',
                    translate: 'MENU.DASHBOARD.USER',
                    role:['Admin','SiteManager','User'],
                    type: 'item',
                    icon: 'users',
                    url: 'user/AllUsers',

                },
                {
                    id: 'partenaire',
                    title: 'Partenaire',
                    translate: 'MENU.DASHBOARD.USER',
                    role:['SuperAdmin'],
                    type: 'item',
                    icon: 'users',
                    url: 'user/AllUsers',
                    hidden: false

                },
                {
                    id: 'indicatorV',
                    title: 'Indicateurs',
                    translate: 'MENU.DASHBOARD.INDICATEUR',
                    type: 'item',
                    role:['SuperAdmin'],
                    icon: 'bookmark',
                    url: 'indicatorV',

                },
                {
                    id: 'versionV',
                    title: 'Versions',
                    translate: 'MENU.DASHBOARD.VERSION',
                    type: 'item',
                    role:['SuperAdmin'],
                    icon: 'hash',
                    url: 'versionV',

                },

            ]
        },

        {
            id: 'EmpreinteCarbonne',
            title: 'Empreinte Carbonne',
            translate: 'MENU.DASHBOARD.EMPREINTECARBONNE',
            role:['Admin'],
            type: 'collapsible',
            icon: 'zap',
            classes:'badge badge-pill badge-light-Secondary waves-effect waves-float waves-light ng-star-inserted',

            children: [
                {
                    id: 'dashboardE',
                    title: 'Tableau de bord',
                    translate: 'MENU.DASHBOARD.DASHBOARD',
                    type: 'item',
                    role:['SuperAdmin','AdminPartner','Admin'],
                    icon: 'monitor',
                    url: 'dashboardE'
                },
                {
                    id: 'employee',
                    title: 'Employés',
                    translate: 'MENU.DASHBOARD.EMPLOYEE',
                    type: 'item',
                    role:['SuperAdmin','AdminPartner','Admin'],
                    icon: 'users',
                    url: 'employee'
                },
                {
                    id: 'deplacement',
                    title: 'Deplacement',
                    translate: 'MENU.DASHBOARD.DEPLACEMENT',
                    type: 'item',
                    role:['SuperAdmin','AdminPartner','Admin'],
                    icon: 'globe',
                    url: 'deplacement'
                }
            ]
        },
        {
            id: 'coolac',
            title: 'Cool@c',
            translate: 'MENU.DASHBOARD.COOLAC',
            type: 'collapsible',
            icon: 'wind',
            hidden: true,
            classes:'badge badge-pill badge-light-Secondary waves-effect waves-float waves-light ng-star-inserted',
            children: [
                {
                    id: 'siteC',
                    title: 'Site',
                    translate: 'MENU.DASHBOARD.SITE',
                    type: 'item',
                    icon: 'map-pin',
                    url: 'siteC'
                },
                {
                    id: 'boxC',
                    title: 'Boitier',
                    translate: 'MENU.DASHBOARD.BOX',
                    type: 'item',
                    icon: 'layout',
                    url: 'boxC'
                },
                {
                    id: 'indicatorC',
                    title: 'Indicateur',
                    translate: 'MENU.DASHBOARD.INDICATEUR',
                    type: 'item',
                    role:['SuperAdmin'],
                    icon: 'bookmark',
                    url: 'indicatorC'
                },
                {
                    id: 'versionC',
                    title: 'Version',
                    translate: 'MENU.DASHBOARD.VERSION',
                    type: 'item',
                    role:['SuperAdmin'],
                    icon: 'hash',
                    url: 'versionC'
                },
                {
                    id: 'settingC',
                    title: 'Paramètre',
                    translate: 'MENU.DASHBOARD.PARAMETRE',
                    type: 'item',
                    role:['SuperAdmin','Admin'],
                    icon: 'settings',
                    url: 'settingC'
                },

            ],
        },

        {
            id: 'droppy',
            title: 'Droppy',
            translate: 'MENU.DASHBOARD.DROPPY',
            type: 'collapsible',
            icon: 'droplet',
            classes:'badge badge-pill badge-light-Secondary waves-effect waves-float waves-light ng-star-inserted',
            children: [
                {
                    id: 'siteD',
                    title: 'Site',
                    translate: 'MENU.DASHBOARD.SITE',
                    type: 'item',
                    icon: 'map-pin',
                    url: 'siteD'
                },
                {
                    id: 'boxD',
                    title: 'Boitier',
                    translate: 'MENU.DASHBOARD.BOX',
                    type: 'item',
                    icon: 'layout',
                    url: 'boxD'
                },
                {
                    id: 'indicatorD',
                    title: 'Indicateur',
                    translate: 'MENU.DASHBOARD.INDICATEUR',
                    type: 'item',
                    role:['SuperAdmin'],
                    icon: 'bookmark',
                    url: 'indicatorD'
                },
                {
                    id: 'versionD',
                    title: 'Version',
                    translate: 'MENU.DASHBOARD.VERSION',
                    type: 'item',
                    role:['SuperAdmin'],
                    icon: 'hash',
                    url: 'versionD'
                },
                {
                    id: 'settingD',
                    title: 'Paramètre',
                    translate: 'MENU.DASHBOARD.PARAMETRE',
                    type: 'item',
                    role:['SuperAdmin','Admin'],
                    icon: 'settings',
                    url: 'settingD'
                },

            ],
        },

        /*
        {
            id: 'site',
            title: 'site',
            translate: 'MENU.DASHBOARD.SITE',
            type: 'item',
            icon: 'grid',
            url: 'site'
        },
        {
            id: 'box',
            title: 'Device',
            translate: 'MENU.DASHBOARD.BOX',
            type: 'item',
            icon: 'layout',
            url: 'box'
        },
        {
            id: 'parametre',
            title: 'Setting',
            translate: 'MENU.DASHBOARD.PARAMETRE',
            type: 'item',
            role:['SuperAdmin','Admin'],
            icon: 'tool',
            url: 'parametre'
        },
        {
            id: 'user',
            title: 'User',
            translate: 'MENU.DASHBOARD.USER',
            role:['Admin','SiteManager'],
            type: 'item',
            icon: 'users',
            url: 'user'
        },
        {
            id: 'indicateur',
            title: 'Indicator',
            translate: 'MENU.DASHBOARD.INDICATEUR',
            type: 'item',
            role:['SuperAdmin'],
            icon: 'bookmark',
            url: 'indicateur'
        },
        {
            id: 'version',
            title: 'Version',
            translate: 'MENU.DASHBOARD.VERSION',
            type: 'item',
            role:['SuperAdmin'],
            icon: 'hash',
            url: 'version'
        },

        {
            id: 'entreprise',
            title: 'Company',
            translate: 'MENU.DASHBOARD.ENTREPRISE',
            type: 'item',
            role:['SuperAdmin'],
            icon: 'globe',
            url: 'entreprise'
        },
        */
    ]
  }
    ];

// Exporter le menu en appelant la fonction pour le créer dynamiquement
/**
 * Function to update the visibility of menu items based on the current project
 */
export function updateMenuVisibilityBasedOnProject() {
    const project = localStorage.getItem('project');
    console.log("*******************************************")
    return project === "Voltix";
}
function updateMenuVisibilityBasedOnProjectVoltix(): boolean {
    const project = localStorage.getItem('project');
    return project === "Voltix";
}




