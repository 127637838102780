export const locale = {
  lang: 'en',
  data: {
    "NUMBER": "{{value}}",
    FOOTER:{
      COPYRIGHT:"All rights Reserved"
    },
    MENU: {
      DASHBOARD: {
        INDICATEUR: 'Indicators',
        VERSION: 'Versions',
        VOLTIX: 'Voltix',
        COOLAC: 'Cool@c',
        DROPPY: 'Droppy',
        ADMINISTRATION:'Administration',
        USER: 'Users',
        SITE: 'Sites',
        PARAMETRE: 'Setting',
        ETAT: 'Equipment',
        HO: 'Menu',
        BOX:'Devices',
        ENTREPRISE:'Companies',
        LOGOUT:'Logout',
        CHANGEPASSWORD:'Password',
        NOTIFICATION:'Notifications',
        PAGENOTFOUND:'Page not found 🕵🏻‍♀️ ',
        MESSAGEPAGENOTFOUND:'Oops! 😖 The requested URL was not found on this server.',
        BTNBACK:'Back to home',
        dashboard:'Dashboard',
        EMPREINTECARBONNE:'Carbon footprint',
        EMPLOYEE:'Employees',
        DEPLACEMENT:'Shift ',
        DASHBOARDE:'Dashboard ',

      }
    },
    VERSION:{
      VERSION_HARD:'Hardware version',
      VERSION_SOFT:'Software version',
      DATE:'Date',
      DESCRIPTION:'Description',
      PROJECT:'Project',
      LINK:'Download link',
      PLACEHOLER:'Choose version type',

      LINKTEXT:'No file uploaded yet.',
      LIST:"List of Software/Hardware versions"
    },
    DEPLACEMENT: {
      MOYENTRANSPORT: 'Transport Mode',
      DISTANCE: 'Distance',
      DATE: 'Date',
      EMPLOYEE: 'Employee',
      DEPLACEMENTS: 'Departures',
      DEPLACEMENT: 'Departure',
      ADD: '+ Business Travel',
    },
    AREA:{
      AREA:'Area',
      CostKWH:'Cost KWH',
      Child:'Sub area',
      DEVICES:'List of devices',
      DEVICE:'device',
      AREADETAIL:'Area details',
      CHOOSSE_DEVICE: 'Device',
      CHOOSSE_CHANNEL:'Channel',
      AFFECT_CHANNEL:'Affect channel',
      DEVICEDETAIL:'Device details',

      NAME:'Name',
      SURFACE:'Surface',
      NBRPORTES:'Number of doors',
      NBRFENETRES:'Number of windows',
      numOfEquipments:'Number of equipment',
      numOfPeople:'Number of people',
    },
    FLOOR:{
      FLOOR:'Floor',
      NAME:'Name',

    },
    ROOM:{
      ROOM:'Room',
      NAME:'Name',
      NUMBEROFEQUIPMENT:'Number of equipments',
      NUMBEROFEMPLOYEES:'Number of employees'

    },
    Planning:{
      addPlanning:'Add',
      planning:'Planning',
      temperature:'Temperature',
      week_day:'Day',
      start_hour:'Start',
      end_hour:'End',
      CapteurMouvement:'Motion sensor',

      UNESEULEFOIS:'Only once',
      TOUSLESJOURS:'Every day',
      TOUTESLESSEMAINES:'Every week',

      LUN:'Mon',
      MAR:'Tus',
      MER:'Wed',
      JEU:'Thu',
      VEN:'Fri',
      SAM:'Sat',
      DIM:'Sun',

      AUJOURDHUI:"Today",
      ClOSE:"Close",
      CALENDAR:"Calendar",
      EMPTYCALENDAR:"Empty calendar",
    },
    LOGIN: {
      WELCOME: 'Start today',
      EXPLAIN: 'Access intelligent management in one click',
      DESCRIPTION: 'Please sign-in to your account and start the adventure',
      CODE: 'Please enter the code received by email',
      FORGETPSW: 'Forgot password?',
      REMEMBERME: 'Remember me',
      SIGNIN: 'Sign in',
      OTPHEADER:'OTP Code',
      OTPBODY:'Enter verification code',
      OTPBUTTON:'Confirm',
    },
    ACTION: {
      MONTRE: 'Show',
      ELEMENTS: 'Elements',
      RECHERCHE: 'Start a research',
      DATEDEBUT: 'Start date',
      DATEFIN: 'End date',
      DATE: 'Date',
      NEW: 'New',
      NEW2: 'New',
      NEW3: 'New',
      UPDATE: 'Update',
      CONFIRMER: 'Confirm',
      ANNULER: 'Cancel',
      ADD: 'Add',
      OBLI:'Is obligatory',
      AFFECTED:'Affected',
      AFFECT:'Affect',
      ACTIONS: 'Actions',
      ACTIVE: 'Enabled',
      DESACTIVE: 'Disabled',
      ACTIVER: 'enabled',
      DESACTIVER: 'Disabled',
      RESOLU: 'Resolved',
      NONRESOLU: 'Unresolved',
      HISTORIQUE: 'History',
      MAP: 'Map',
      UPDATEPSW: 'Password update',
      UPDATE_VERSION: 'Update device version',
      UPDATE_VERSION_AWAIT: 'Version update scheduled',
      REPORT:'Export report',
      Planification: 'Plan',
      CREATED:'Create',
      DELETE:'Delete',
      UPLOAD:"Upload",
      PLANNING:"Planning",
      QUITTER:"Quit",
    },
    USER: {
      USER: 'User',
      user: 'User',
      SITE_MANAGER:'Site Manager',
      EMAIL: 'Email',
      LASTNAME: 'First name',
      FIRSTNAME: 'Last name',
      USERNAME: "Username",
      PHONE:'Phone',
      PASSWORD:'Password',
      CODE:'Code',
      ETAT:'State',
      ROLE:'Role',
      STATUS:'Status',
      ENTREPRISE:'Company',
      ADD:'Add user',
      UPDATE:'Update user',
      ADMIN_PARTNER : 'Admin Partner'


    },
    EMPLOYEE: {
      EMPLOYEE: 'Employee',
      EMAIL: 'Mail',
      LASTNAME: 'Last name',
      FIRSTNAME: 'First name',
      USERNAME: "Employee's name",
      PHONE: 'Phone',
      PASSWORD: 'Password',
      DISTANCE: 'Distance',
      MOYENTRANSPORT: 'Transport method',
      ROLE: 'Role',
      TELETRAVAIL: 'Remote work',
      ENTREPRISE: 'Company',
      ADD: 'Add Employee',
      LISTEEMPLOYEE: 'Employee list',
      CAR_USE: 'Car',
      USER_MOTOR_USE: 'Motorbike',
      WALK_PERCENTAGE: 'Walk',
      BUS_USE: 'Bus',
      METRO_USE: 'Metro',
      TRAIN_USE: 'Train',
      NUMBER_OF_DAYS: 'Telework'
    },
    ENTREPRISE: {
      ENTREPRISES: 'Companies',
      ENTREPRISE: 'Company',
      MANAGER: 'Administrator',
      NAME: 'Name',
      LOGO: 'Logo',
      LOGOEXEMPLE: 'LOGO example: https://...',
      numTVA:'VAT number',
      adressePostal:'postal address',
      LIST:'List of Companies'

    },
    ALARME: {
      AREA: 'no Area',
    },
    BOX: {
      BOX: 'Device',
      CHANNEL:'Channel',
      box: 'Device',
      DEVICES:'Devices',
      GENERATE:'Generate',
      number:'Number',
      AVANT: 'Front',
      ARRIERE: 'Back',
      MONTE:'Mounted',
      DEMONTE:'Dismount',
      REF:'Serial number',
      OWNER:'Owner',
      NomSite: 'Site name',
      STATUS:'Status',
      LASTMAJ:'Last update software',
      NOTIFICATION:'Notification',
      TIMELINE:'Timeline',
      ALERT:'Alert',
      ALERTS:'Alerts',
      DATE:'Date',
      LIBELLE:'Title',
      CRITIC:'Description',
      DEFAULTVALUE1:'Default min value',
      DEFAULTVALUE2:'Default max value ',
      DATEDEBUT:'Start planification ',
      DATEFIN:'End planification ',
      CODE:'Code',
      ALARME:'Alarm',
      INDICATEUR:'Indicator',
      PARAMETRE:'Setting',
      UNITE:'Unit',
      ATTRIBUT:'Attribute',
      typeBox: 'Device type',
      Etat: 'Equipment',
      ENABLE:'ENABLE',
      DISABLE: 'DISABLE',
      COLOR:'Color',
      DESCRIPTION:'Description',
      LOWER_UPPER_BOUND:'Lower & upper bounds',
      LOWER_BOUND_ONLY:'Lower bound only',
      UPPER_BOUND_ONLY:'Upper bound only',
      LOWER_BOUND:'Lower Bound',
      LOWER_BOUND_INCLUDED:'Lower bound included',
      TITLE:'Title',
      UPPER_BOUND:'Upper Bound',
      UPPER_BOUND_INCLUDED:'Upper bound included',
      RANGES:'RANGES',
      TYPEALERTE:"Type of alerts",
      PASCONNECTE:"Not connected",
      PAIRED:"Paired",
      UNPAIRED:"Unpaired",
      MANUFACTURED:"Manufactured",
      PAIREDBUTDOWN:"Paired, Not connected",
      BOUND:'Bound',
      ASS_THRESHOLD: 'Association threshold to device',
      THRESHOLD: 'Threshold',
      EMPTY:'List of thresholds for this device is empty.',
      TYPE:'KIND',

      KEEPALIVE : "Keep alive",
      KEEPALIVEBUTTON : "Change keep alive",
      KEEPALIVELABEL : "Insert the new keep alive value in milliseconds",
    },
    Dashboard: {
      statistique: 'Statistics',
      vehicule: 'Vehicles',
      customers: 'Customers',
      users: 'Users',
      free: 'Free',
      boxes: 'Devices',

    },
    USERS: {
      USER : "List of users",
      ADMIN_PARTNER : "List of administrators partners"
    },
    EQUIPMENT: {
      EQUIPMENT: 'Equipment',
      ID: 'Code',
      BRAND: 'Brand',
      DESIGNATION: 'Designation',
      NAME: 'Name',
      NUMBER: 'Number',
      POWER: 'Power',
      AREA: 'Area',
      RELAY:"Relay",
      DURATION:'Duration',
      SN:'Serial number',
      ACTADD:'Address',
      ACTMODULE:'Module',
      ACTVAL:'State',
      TYPE:'Type',
      ADDRESS:'Address',
      SENSOR:'Sensor',

      EQUIPMENTSTATUS:'Status',
      TEMPERATURE:'Temperature',
      COOLINGCAPACITY:'Cooling capacity',
    },
    COMPARE: {
      COMPARE: 'Compare',
      AREA: 'Areas',

    },
    ALERTES: {
      ALERTES: 'Alerts',
      AREA: 'Areas',

    },
    SITE: {
      SITE: 'Site',
      notif:'Notifications',
      NomSite: 'Site',
      NUMEROSERIE: 'Serial number',
      SELLETTE1: 'SELLETTE 1',
      ETAT: 'STATE',
      SELLETTE2: 'SELLETTE 2',
      ACTIONS: 'Actions',
      NEWSITE: 'New site',
      NEWAREA: 'New area',
      INSTALLDATE: "Installation date",
      LASTCNX: 'Last connection',
      etat: 'Status',
      IMEI: 'Mac address',
      VERSION: 'Software version',
      MAPS: 'Geographic position',
      POSITION: 'Site location',
      longitude: 'Longitude',
      latitude: 'Latitude',
      LOCALISATION: 'Location',
      BOITIER: 'Devices',
      EQUIPMENT: 'Equipment',
      CONTROL: 'Control',

      sn:'Serial number',
      lc:'Last connection',
      planning:'Planning',
      measures:'Measures',
      area:'Area',

      RESOLVEDBY:'Resolved By',
      RESOLVED:'Revolved',
      NOTRESOLVED:'Not resolved',

    },
    TOAST: {
      ADD: 'Successfully added',
      MOUNT: 'Successfully mounted',
      UNMOUNT: 'Successfully unmounted',
      AFFECT: 'Successfully affected',
      GENERATE: 'Successfully generated',
      ClOSE: 'Successfully closed',
      UPDATE: 'Successfully updated',
      logged: 'You have successfully logged in',
      loggedMessage: '👋 Welcome, ',
      loggedMessageVoltix: '👋 Welcome, on Voltix',
      loggedMessageCoolac: '👋 Welcome, on Coolac',
      loggedMessageDroppy: '👋 Welcome, on Droppy',
      loggedMessageEmpreinteCarbonne: '👋 Welcome, on Carbon footprint',

    },
    INDICATEUR: {
      borneInf: 'Lower bound',
      borneSup: 'Upper bound',
      seuil: 'Alarm thresholds',
      indicateursConfigure: 'Configured indicators',
      LIST:'List of Indicators'
    },
    VUEUNIFIE:{
      BIENVENUE:'Welcome',
      MESSAGE1:'You can now monitor and control your Buildings',

      NOTEVOLTIX:'Optimize your consumption with Voltix, save the planet and reduce your expenses',
      NOTECOOLAC:'Automatic comfort, controlled bill : AI at the service of your well-being',
      NOTEDROPPY:'Leaks stopped, consumption controlled: technology at the service of your peace of mind',
      NOTEWHOISIN:'Facial recognition to streamline your activities and increase your security',

      NOTEVOLTIX1:'Optimize your consumption,',
      NOTEVOLTIX2:'save the planet and reduce your expenses',

      NOTECOOLAC1:'Automatic confort ',
      NOTECOOLAC2:', controlled bill ',
      NOTECOOLAC3:': AI at the service of your Well-Being',

      NOTEDROPPY1:'Leaks stopped ',
      NOTEDROPPY2:', consumption controlled: technology at the service of your peace of mind',

      NOTEWHOISIN1:'Facial recognition to ',
      NOTEWHOISIN2:'streamline ',
      NOTEWHOISIN3:'your activities and ',
      NOTEWHOISIN4:'increase your security',
    },
    DASHBOARD: {
      GLOBALDASHBOARD:'Global Dashboard',
      GLOBALCONSUMPTION:'Global Consumption',
      TOTALALERTS:'Total alerts',
      THEMOSTCONSUMINGSITES	:'Consumption per site',
      TOP3MOSTCONSUMINGSITES:'Top 3 Most Consuming sites',
      TOP3HIGHALERTS:'Top 3 High Alerts',
      CONSUMPTIONRATEPERSITES	:'Consumption rate per site',
      RESEARCH:'Research',
      GOBACK:'Go Back',
      EXPORTREPORT:'Export report',
      CONSUMPTION:'Consumption',
      METER:'Meter',
      CHANNEL:'Channel',
      AREA:'Area',
      ROOM:'Room',
      CONSOSITEPARJOUR: "Site consumption / day",
      MOYENNECONSO:"Average of the week",
      TOTALCONSO:'Total consume',
      VOIRPLUS: ' See more' ,
      MONTH:'Month',
      TOTALCONSOMME:"Total consumed",
      COUT:"Cost",
      FILTER:"Select a filter",
      FILTERTIME:"Select a time",
      CHALLANGE:"Challenge for this month",
      REDUCE:"Reduce your bill by 5%",
      EMPRESSE_CARBONE:"Carbon Footprint",
      TITREDATA:"No data found",
      TEXTEDATA:"Sorry for the inconvenience but we are currently carrying out maintenance.",

      TITRE2DATA:"No buildings are currently added for this business",
      TEXTE2DATA:"Please add a building to continue.",

      CONFIRMER: "Confirm",
      PASSER: "Skip"
    },
    DASHBOARDSITE: {
      LISTOFAREAS:'List of areas inside the site',
      SITECONSUMPTION:'Site consumption',
      THEMOSTCONSUMINGAREAS	:'Consumption per area',
      TOP3MOSTCONSUMINGAREAS:'Top 3 Most Consuming areas',
      CONSUMPTIONRATEPERAREAS:'Consumption rate per area',
      CONSOAREAPARJOUR:'Area consumption / day',
      EQUIP: 'equipment',
      PEOPLE:'people',

      TITREDATA:"No device connected to this Site",
      TEXTEDATA:"Please connect a device to view consumption data.",

      TITRE2DATA:"No devices are connected to any area of this site",
      TEXTE2DATA:"Devices are assigned to the site but are not linked to a zone.",

      AFFECT:"Add a box for this site",
      DEVICE:'Device details',

      COUT_DU_KWH:'kWh Cost',
      DEVISE:'Currency',

      HOLIDAY:'Holiday',
      HOLIDAY_DESCRIPTION:'Description',
      HOLIDAY_DATE:'Date',
    },
    DASHBOARDAREA: {
      MEASURE:'Measure',
      PDF:'Download as PDF',
      CSV:'Download as CSV',
      AREACONSUMPTION:'Area consumption',
      AFFECT:"Adding a box for the zone",
      DEVICE:'Box details',
      ESTIMATED:'Estimated amount of your Consumption',
      INVOICEDETAIL:'INVOICE DETAIL',
      LANDING:'Landing',
      AMOUNT:'Amount',
      CONSUMPTIONCOST:'Consumption cost (Ex. VAT)',
      TAXES	:'Taxes (VAT)',
      ALLTAXESINCLUDED	:'All Taxes Included',

      DISAGGREGATION	:'Disaggregation ',

      CAMENBERT: "of consumption by type of equipment",
      BARCUMULEE: "Total consumption by type of equipment",
      BAR: "Consumption by type of equipment",

      COST:'Cost',
      CONTROL:'Controls',

      TITREDATA:"No Zone is currently added for this site",
      TEXTEDATA:"Please add a building to continue.",

      TITRE2DATA:"No devices connected to this area",
      TEXTE2DATA:"Please connect a device to view consumption data."
    },
    AFFECT: {
      AFFECTUSER: 'Assign a user to a zone',
      SELECTZONE: 'Select the zone',
      AFFECTTOAREA: 'Assign',
      AFFECTTOSITE:'Assign user to the site',
      SELECTSITE:'Select the site',
      MANAGER:'Manager'


    },
    THRESHOLDS: {
      LIST:'List of settings'
    },


    'Tension':'Voltage',
    'Courant':'Current',
    'Puissance active': 'Active power',
    'Puissance réactive': 'Reactive power',
    'Puissance apparente': 'Apparent power',
    'Facteur de puissance': 'Power factor',

    'Puissance active totale':'Total active power',
    'Puissance réactive totale':'Total reactive power',
    'Puissance apparente totale':'Total energy',
    'Énergie active totale':'Total active energy',
    'Énergie réactive totale':'Total reactive energy',
    'Facteur de puissance total':'Total power factor',
    'Tension entre Ph1-Ph2':'Voltage between Ph1-Ph2',
    'Tension entre Ph2-Ph3':'Voltage between Ph2-Ph3',
    'Tension entre Ph3-Ph1':'Voltage between Ph3-Ph1',

    PhaseToPhase: 'Phase to phase',
    MEASURE: {
      Active_pow:'Active power',
      ph_to_line_volt:'Voltage between phases',
      Current:'Current',
      reactive_pow:'Reactive power',
      apparent_pow:'Apparent power',
      pow_factor:'Power factor',
      freq:'Frequency',
      tot_reactive_pow:'Total reactive power',
      tot_apparent_pow:'Total active power',
      tot_active_energ:'Total active energy',
      tot_reactive_energ:'Total reactive energy',
      tot_pow_factor:'Total power factor',
      ph1_to_ph2_volt:'Voltage between phase 1 and phase 2',
      ph2_to_ph3_volt:'Voltage between phase 2 and phase 3',
      ph3_to_ph1_volt:'Voltage between phase 3 and phase 1',
      tot_active_pow:'Total active power',
      voltage:'Voltage',
      ActivePower : 'Active power',
      ReactivePower : 'Reactive power',
      ApparentPower : 'Apparent power',
      PowerFactor : 'Power factor',
      NoValues:'No value',
      TotalEnergie:'Total energy',
      TotalPower:'Total power',
      PhaseToPhase: 'Phase to phase'
    },

    DEVICES: {
      LIST:'List of devices'
    },

    DELETE:{
      INDICATOR:"Do you really want to delete this indicator ?",
      USER:"Do you really want to delete this user ?",
      PARAMETRE:"Do you really want to delete this settings ?",
      VERSION:"Do you really want to delete this version ?",
      AREA:"Do you really want to delete this area ?",
      SITE:"Do you really want to delete this site ?",
      EQUIPEMENT:"Do you really want to delete this equipment ?",

      CONFIRMOFF:"Do you really want to OFF this equipment ?",
      CONFIRMON:"Do you really want to ON this equipment ?",
      DEMANDEON : "Do you really want to request to turn ON this equipment?",
      DEMANDEOFF : "Do you really want to request to turn OFF this equipment ?",

      YES:"Yes",
      NO:"No"
    },
    RESOLVED: {
      ALERTE: "Do you really want to confirm that this issue is resolved?",
      YES: "Yes",
      NO: "No"
    }
  }
};
