<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
    <!-- Navbar brand -->
    <ul class="nav navbar-nav flex-row">
        <li class="nav-item">
            <a [routerLink]="['/']" class="navbar-brand">
        <span class="brand-logo">
          <img alt="brand-logo" src="{{ coreConfig.app.appLogoImage }}" width="36"/>
        </span>
                <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
            </a>
        </li>
    </ul>
    <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul *ngIf="!coreConfig.layout.menu.hidden" class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <a (click)="toggleSidebar('menu')" class="nav-link menu-toggle">
                    <span [class]="'ficon'" [data-feather]="'menu'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->

        <!-- Language selection -->
        <div class="nav-item dropdown dropdown-language" ngbDropdown>
            <a class="nav-link dropdown-toggle align-items-center justify-content-between" id="dropdown-flag"
               ngbDropdownToggle>
                <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i>
                <span class="selected-language text-black">
                        {{ languageOptions[_translateService.currentLang].flag | uppercase }}
                    - {{ languageOptions[_translateService.currentLang].title }}</span></a>
            <div aria-labelledby="dropdown-flag" ngbDropdownMenu>
                <a (click)="setLanguage('fr')" ngbDropdownItem class="text-black">
                    <i class="flag-icon flag-icon-{{ languageOptions['fr'].flag }}"></i> {{ languageOptions['fr'].flag | uppercase }}
                    - {{ languageOptions['fr'].title }}
                </a>
                <a (click)="setLanguage('en')" ngbDropdownItem class="text-black">
                    <i class="flag-icon flag-icon-{{ languageOptions['en'].flag }}"></i> {{ languageOptions['en'].flag | uppercase }}
                    - {{ languageOptions['en'].title }}
                </a>
            </div>
        </div>
        <!--/ Language selection -->


        <!-- Toggle skin -->
        <div class="nav-item d-none d-lg-block">
            <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
            </a>
        </div>
        <!--/ Toggle skin -->


    </div>
    <!-- Notification -->
    <ul class="nav navbar-nav align-items-center " >
    <app-navbar-notification></app-navbar-notification>
    </ul>

    <!--/ Notification -->
    <ul class="nav navbar-nav align-items-center ml-auto">
        <!-- Sites/Devices/Areas -->
        <li class="nav-item dropdown dropdown-language" ngbDropdown>
            <div [routerLink]="['/dashboard']"
                 class=" badge badge-pill badge-light-secondary mr-1" ngbPopover="Sites"
                 placement="bottom"
                 style="cursor: pointer;"
                 triggers="mouseenter:mouseleave">
                <img
                        height="25"
                        src="./assets/images/icons/site.png"
                        title="Sites"
                        width="25"
                />
                {{ counts?.countSites || 0 }}
            </div>

            <div [routerLink]="['/boxV']"
                 class=" badge badge-pill badge-light-secondary mr-1" ngbPopover="{{'BOX.BOX' | translate}}s"
                 placement="bottom"
                 style="cursor: pointer;"
                 triggers="mouseenter:mouseleave">
                <img
                        height="25"
                        src="./assets/images/elements/box5.png"
                        title="{{'BOX.BOX' | translate}}s"
                        width="25"
                />
                {{ counts?.countDevices || 0 }}
            </div>

            <div
                    class=" badge badge-pill badge-light-secondary" ngbPopover="{{'AREA.AREA' | translate}}s"
                    placement="bottom"
                    style="cursor: pointer;"
                    triggers="mouseenter:mouseleave">
                <img
                        height="25"
                        src="./assets/images/icons/area.png"
                        title="{{'AREA.AREA' | translate}}s"
                        width="25"
                />
                {{ counts?.countAreas || 0 }}
            </div>
        </li>
        <!-- Sites/Devices/Areas -->

        <!-- Toggle skin -->
        <!--<li class="nav-item d-none d-lg-block">
          <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
            <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
          </a>
        </li>-->
        <!--/ Toggle skin -->

        <!-- User Dropdown -->
        <li class="nav-item dropdown-user" ngbDropdown>
            <a
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="nav-link dropdown-toggle dropdown-user-link align-content-between justify-content-between"
                    id="dropdown-user"
                    id="navbarUserDropdown"
                    ngbDropdownToggle
                    style="text-align: center"
            >
                <ng-container *ngIf="this.currentUser">
                    <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
            >{{ this.currentUser?.firstname }} {{ this.currentUser?.lastname }}</span
            ><span class="user-status">{{ this.currentUser?.role }}</span>
                    </div>
                    <span class="avatar"
                    >
                      <img
                              alt="avatar"
                              class="round"
                              height="40"
                              src="{{ this.currentUser?.role === 'AdminPartner' ? './assets/images/elements/entreprise.png' : (this.currentUser?.client?.logo || './assets/images/icons/sfmLogo.png') }}"
                              width="40"
                      />
                  <span class="avatar-status-online"></span>


                    </span>
                </ng-container>
            </a>
            <div aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <a (click)="logout()" ngbDropdownItem><span [class]="'mr-50'"
                                                            [data-feather]="'power'"></span> {{'MENU.DASHBOARD.LOGOUT' | translate}}
                </a>
                <a (click)="changePassword()" ngbDropdownItem><span [class]="'mr-50'"
                                                                    [data-feather]="'lock'"></span> {{'MENU.DASHBOARD.CHANGEPASSWORD' | translate}}
                </a>

            </div>
        </li>
        <!--/ User Dropdown -->
    </ul>
</div>
