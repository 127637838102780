// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    /************************* AUTRES CONFIG **********************/
    //url:"http://192.168.90.11:8080/api/v1/",

    /************************* CONFIG EN LOCAL **********************/
    //url:"http://localhost:8080/sfmconnectback/api/v1/",
    //url:"http://localhost:8080/sfmconnect2/api/v1/",
    //url:"http://localhost:8080/sfmconnect2harmonic/api/v1/",

    //url:"http://localhost:8080/sfmconnect/api/v1/",
    //webSocketUrl:"http://localhost:8083/collectevoltix/ws",
    //webSocketUrlAlerts:"http://localhost:8080/sfmconnect/ws",

    /************************ CONFIG EN SERVER *********************/
    //url:"http://vps-b7fc2ba4.vps.ovh.net:8080/sfmconnectback/api/v1/",
    //url:"http://vps-b7fc2ba4.vps.ovh.net:8080/sfmconnect2/api/v1/",
    //url:"http://vps-b7fc2ba4.vps.ovh.net:8080/sfmconnect2harmonic/api/v1/",

    url:"https://sfmconnect.sfmtechnologies.net:8443/sfmconnect/api/v1/",
    webSocketUrl:"https://sfmconnect.sfmtechnologies.net:8443/collectevoltix/ws",
    webSocketUrlAlerts:"https://sfmconnect.sfmtechnologies.net:8443/sfmconnect/ws",


    refresh_time: 3000,

};