import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import {CoreMenu} from "../../types";

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
      this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();

      this._changeDetectorRef.markForCheck();
    });
  }
  toggleCollapsible(item: CoreMenu): void {
    if (item.disabled) {
      // Empêche l'action si l'élément est désactivé
      console.log(`${item.title} is disabled.`);
      return;
    }

    // Change l'état d'ouverture seulement pour les éléments de type 'collapsible'
    if (item.type === 'collapsible') {
      item.isOpen = !item.isOpen; // Suppose que vous ajoutez un attribut isOpen à CoreMenu

      // Optionnellement, fermez tous les autres éléments 'collapsible' lorsqu'un est ouvert
      this.menu.forEach(menuItem => {
        if (menuItem.id !== item.id && menuItem.type === 'collapsible') {
          menuItem.isOpen = false;
        }
      });
    }
  }
}
