
<li ngbDropdown class="nav-item dropdown-notification mr-25">
    <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown2"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{alarmes.length}}</span></a
    >
    <ul
            ngbDropdownMenu style="width: 600px;"
            aria-labelledby="navbarNotificationDropdown"
            class="dropdown-menu dropdown-menu-media dropdown-menu-left"
    >
        <!-- Notifications header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">{{'MENU.DASHBOARD.NOTIFICATION' | translate}}</h4>
            </div>
        </li>
        <!--/ Notifications header -->

        <!-- Notifications content -->
        <li class="scrollable-container media-list" [perfectScrollbar]>
            <a class="d-flex" href="javascript:void(0)" *ngFor="let alarm of alarmes;" (click)="openDialog(templateGG)">

                <div class="media d-flex align-items-start">
                    <div class="media-left">
                        <div class="avatar" style="background-color:black">
                            <div  class="avatar-content"><i class="avatar-icon" data-feather="zap"></i></div>
                            <!--    <div  *ngIf="alarm?.device?.project == 'Droppy'" class="avatar-content font-large-3"><i class="avatar-icon" data-feather="droplet"></i></div>
                                <div *ngIf="alarm?.device?.project == 'Coolac'" class="avatar-content"><i class="avatar-icon" data-feather="wind"></i></div> -->
                        </div>
                    </div>
                    <div class="media-body">
                        <p *ngIf="alarm?.threshold" class="media-heading" style="color: black;">
                     <strong>  {{alarm?.threshold?.site.client?.name }}
                            - {{alarm?.threshold?.site.name}} </strong>  </p>
                        <p *ngIf="!alarm?.threshold && alarm?.deviceThreshold != null" class="media-heading" style="color: black;">
                            <strong>   {{alarm?.deviceThreshold?.threshold?.site.client?.name }}
                            - {{alarm?.deviceThreshold?.threshold?.site.name}} </strong>  </p>
                        <p *ngIf="!alarm?.threshold && !alarm?.deviceThreshold " class="media-heading" style="color: black;">
                            <strong>   {{alarm?.anomaly?.device?.client?.name }}
                                 </strong>  </p>
                        <p *ngIf="!alarm?.threshold && alarm?.deviceThreshold != null" class="media-heading">
                            {{alarm?.deviceThreshold?.device?.serialNumber }}
                        </p>
                        <p *ngIf="!alarm?.threshold && !alarm?.deviceThreshold " class="media-heading">
                            {{alarm?.anomaly?.device?.serialNumber }}
                        </p>


                        <!-- Si alarm?.threshold n'est pas null -->
                        <p *ngIf="alarm?.threshold" class="media-heading">
                            <i class="avatar-icon" data-feather="hash"></i>
                            {{alarm?.threshold.description}}

                        </p>
                        <!-- Si alarm?.threshold est null et deviceThreshold not null   -->
                        <p *ngIf="!alarm?.threshold && alarm?.deviceThreshold!= null" class="media-heading">
                            <i class="avatar-icon" data-feather="hash"></i>
                            {{alarm?.deviceThreshold?.threshold.description}}
                        </p>
                        <!-- Si alarm?.threshold est null et deviceThreshold  null  ****** Anomaly ******  -->
                        <p *ngIf="!alarm?.threshold && !alarm?.deviceThreshold" class="media-heading">
                            <i class="avatar-icon" data-feather="hash"></i>
                            {{alarm?.anomaly?.description}}
                        </p>
                        <div style="color: blue;" class="media-heading"><i class="avatar-icon" data-feather="clock"></i>
                            {{alarm?.date | date :'dd-MM-yyyy HH:mm'}}
                        </div>
                        <div *ngIf="alarm?.phase" class="text-white media-heading badge badge-pill" style="text-align:start ;background-color:{{alarm?.setting?.color}}">
                            Meter {{alarm?.meter}} <i class="avatar-icon" data-feather="chevrons-right"></i>
                            Channel {{alarm?.channel}} <i class="avatar-icon" data-feather="chevrons-right"></i>
                            Phase {{alarm?.phase}}
                        </div>
                    </div>
                    <div class="media-right">
                        <!-- Si alarm?.threshold n'est pas null -->
                        <div *ngIf="alarm?.threshold" class="media-right">
                            <div class="text-white media-heading badge badge-pill" style="text-align:start; background-color:{{alarm.threshold?.color}}">
                                <small class="media-heading">Alerte {{alarm?.threshold?.props}}</small>
                            </div>
                        </div>

                        <!-- Si alarm?.threshold est null -->
                        <div *ngIf="!alarm?.threshold && alarm?.deviceThreshold != null " class="media-right">
                            <div class="text-white media-heading badge badge-pill" style="text-align:start; background-color:{{alarm.deviceThreshold?.threshold?.color}}">
                                <small class="media-heading">Alerte {{alarm?.deviceThreshold?.threshold?.props}}</small>
                            </div>
                        </div>
                        <!-- Si alarm?.threshold est null et deviceThreshold  null  ****** Anomaly ******  -->
                        <div *ngIf="!alarm?.threshold && !alarm?.deviceThreshold  " class="media-right">
                            <div class="text-white media-heading badge badge-pill" style="text-align:start; background-color:red;">
                                <small class="media-heading">Anomalie {{alarm?.anomaly?.anomalyName}}</small>
                            </div>
                        </div>
                        <div class="media-heading">
                            <i class="avatar-icon" data-feather="hash"></i>
                            {{alarm.anomaly?.area?.name || 'ALARME.AREA' | translate}}
                        </div>
                    </div>
                </div>
                <ng-template #templateGG>
                    <div class="modal-body text-center">
                        <p>{{ "RESOLVED.ALERTE" | translate }}</p>
                        <button (click)=" modalRef?.hide();resolved(alarm?.id)" class="btn btn-danger mr-2"
                                type="button">{{ "RESOLVED.YES" | translate }}
                        </button>
                        <button (click)="modalRef?.hide()" class="btn btn-default"
                                type="button">{{ "RESOLVED.NO" | translate }}
                        </button>
                    </div>
                </ng-template>
            </a>


        </li>
        <!--/ Notifications content -->
    </ul>
</li>