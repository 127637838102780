import { Injectable } from '@angular/core';
import { Client, Message, Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { Subject, Observable } from 'rxjs';
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AlertesWebSocketService {
    private client: Client;
    private alertSubject = new Subject<any>();

    constructor() {
        this.initializeWebSocketConnection();
    }

    initializeWebSocketConnection() {
        this.client = Stomp.client(environment.webSocketUrlAlerts);
        this.client.webSocketFactory = () => {
            return new SockJS(environment.webSocketUrlAlerts);
        };

        this.client.onConnect = (frame) => {
            this.client.subscribe('/topic/alerts', (message: Message) => {
                try {
                    const body = JSON.parse(message.body);
                    this.alertSubject.next(body);
                } catch (e) {
                   //console.error('Error parsing JSON: ', e);
                }
            });
        };

        this.client.activate();
    }

    getAlertUpdates(): Observable<any> {
        return this.alertSubject.asObservable();
    }

    sendAlert(message: string) {
        if (this.client && this.client.connected) {
            this.client.publish({ destination: '/app/alert', body: JSON.stringify(message) });
        }
    }

    disconnect() {
        if (this.client) {
            this.client.deactivate();
        }
    }
}
