import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onApiDataChange = new BehaviorSubject('');
  }

 

  /*getAlarmeCount() {
    return  this._httpClient.get(`${environment.url}alarms/alarmeCount`).toPromise();
  }*/
  resolved(id){
    return this._httpClient.post(`${environment.url}alarms/alarmeResolved`,id).toPromise();
  }
  getAllAlarme() {
    return  this._httpClient.post(`${environment.url}alarms/allAlarmes`,false).toPromise();
  }
  counts(clientId, project) {
    return this._httpClient.get(`${environment.url}clients/counts?idClient=${clientId}&project=${project}`).toPromise();
  }

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

}
