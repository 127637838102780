import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  //private

  constructor(private _router: Router, private _http: HttpClient, private _toastrService: ToastrService,  private _translateService: TranslateService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));

    this.currentUser = this.currentUserSubject.asObservable();
  }
  changePassword(userDto){
   //console.log(userDto)
    return this._http
        .post<any>(`${environment.url}users/changePassword`, userDto);

  }

  codeverification(email: string, password: string){
    return this._http
    .post<any>(`${environment.url}auth/authenticate`, { email, password })
    .pipe(
      map(user => {
        return user;
      })
    );
  }


  isManager (userId, siteId){
    return this._http.get(`${environment.url}users/managed?userId=${userId}&siteId=${siteId}`).toPromise();

  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }


  login(email: string, otp: string) {
    return this._http
      .post<any>(`${environment.url}auth/validate_authenticate`, { email, otp })
      .pipe(
        map(user => {
          //console.log(user)
          // login successful if there's a jwt token in the response
          if (user.data.user && user.data.token) {
            user.data.user["token"]=user.data.token;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.data.user));
            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                this._translateService.instant('TOAST.logged'),
                this._translateService.instant('TOAST.loggedMessage'),
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user.data.user);
          }

          return user.data.user;
        })
      );
  }

  logout() {
    // Utilisez la méthode GET pour la déconnexion
    this._http.get(`${environment.url}auth/logout`).subscribe(() => {
      // Supprimer l'utilisateur du stockage local pour le déconnecter
      localStorage.removeItem('currentUser');
      // Navigation vers la page de connexion après la déconnexion réussie
      this._router.navigate(['/authentication/']);
      this.currentUserSubject.next(null);
    },
    () => {
      this._router.navigate(['/authentication/']);

    }
    );
  }
}
