import {Component, OnInit, TemplateRef} from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { environment } from '../../../../../environments/environment';
import {AuthenticationService} from "../../../../auth/service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AlertesWebSocketService} from "./alertes-web-socket.service";
import {Subscription} from "rxjs";


// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  private subscription: Subscription;
  alarmes=[];

  /**
   *
   * @param {NotificationsService} _notificationsService
   * @param auth
   * @param modalServiceN
   */
  constructor(
      private _notificationsService: NotificationsService,
      private auth: AuthenticationService,
      private modalServiceN: BsModalService,
      private webSocketService: AlertesWebSocketService
  ) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  refreshIntervalId
  ngOnInit(): void {
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
    });
    // this.countAlarme();
    // this.getAllAlarmes();
    const forbiddenRoles = ['SuperAdmin', 'AdminPartner'];
    this.getAllAlarmes();
    this.subscription = this.webSocketService.getAlertUpdates().subscribe(alert => {
     //console.log("alert**************" + alert)
      this.alarmes.push(alert);
    });
   /* if (this.auth.currentUserValue && !forbiddenRoles.includes(this.auth.currentUserValue.role))
    {
      this.refreshIntervalId = setInterval(() => {
        //this.countAlarme();
        //console.log("countAlarme !!")
        this.getAllAlarmes();
      }, environment.refresh_time);
    } */
  }


  ngOnDestroy(): void {
    clearInterval(this.refreshIntervalId);
  }


  count=0;
  /*countAlarme() {
    this._notificationsService.getAlarmeCount().then((data: any) => {

      if(this.count!=data.data){
        this.count=data.data;
        this.getAllAlarmes();
      }else{
        this.count=data.data;
      }

    })
  }*/


  getAllAlarmes() {
    this._notificationsService.getAllAlarme().then((data: any) => {
      if(data.data!=null)
      this.alarmes=data.data;
//     //console.log("get Alarmes   ::    ",data)
    /*  for(let al of this.alarmes){
       //console.log("Channel :  "+ al.channel)
      } */
    })
  }



  resolved(id){
    this._notificationsService.resolved(id).then((data: any) => {
      this.getAllAlarmes();
      //this.countAlarme();
    });
  }
  modalRef?: BsModalRef;
  openDialog(template: TemplateRef<any>){
    this.modalRef = this.modalServiceN.show(template);
  }
  detailBoxe(box): void {
    this._notificationsService.changeMessage(box)
  }
}
